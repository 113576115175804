import InitialiseProductList from "./productList";

var ARCADIA = ARCADIA || {};

ARCADIA.SCREEN_INFO_SERVICE = (function () {
	var userIsTouching = false;

	var attachHandlers = function () {
		window.addEventListener("touchstart", onFirstTouch, false);
	};

	var onFirstTouch = function () {
		userIsTouching = true;
		window.removeEventListener("touchstart", onFirstTouch, false);
	};

	var isUserTouching = function () {
		return userIsTouching;
	};

	var isSmallMobile = function () {
		return window.innerWidth < 450;
	};

	var isMobile = function () {
		return window.innerWidth < 768;
	};

	var isInsideMobileNavBreakPoint = function () {
		return window.innerWidth < 1024;
	};

	var isSmallDesktop = function () {
		return window.innerWidth < 1219;
	};

	return {
		isSmallMobile: isSmallMobile,
		isMobile: isMobile,
		isInsideMobileNavBreakPoint: isInsideMobileNavBreakPoint,
		isSmallDesktop: isSmallDesktop,
		attachHandlers: attachHandlers,
		isUserTouching: isUserTouching,
	};
})();

ARCADIA.CONTENT_REVEALER = (function () {
	//all this is needed because css transitions don't work with height auto
	var heightTransitionClass = "height-transition";
	var expectedTransitionTime = 35;

	var revealContent = function ($content, done) {
		showHideContent(true, $content, done);
	};

	var hideContent = function ($content, done) {
		showHideContent(false, $content, done);
	};

	var showHideContent = function (
		show,
		$content,
		customOnTransitionComplete
	) {
		var transitionComplete = false;

		if (show) {
			startRevealTransition();
		} else {
			startHideTransition();
		}

		function startRevealTransition() {
			var height;
			removeTransitionCSS();
			showContent();
			height = $content.height();
			$content.height(0);
			setTransitionCSS();
			$content.height(height);
			makeHeightAutoAfterTransition();
		}

		function startHideTransition() {
			var height;
			height = $content.height();
			$content.height(height);
			setTransitionCSS();
			$content.height(0);
		}

		function setTransitionCSS() {
			$content.addClass("height-transition");
		}

		function removeTransitionCSS() {
			$content.removeClass("height-transition");
		}

		function showContent() {
			$content.css("height", "auto");
		}

		function makeHeightAutoAfterTransition() {
			setTimeout(function () {
				showContent();
			}, expectedTransitionTime + 150);
		}
	};

	return {
		revealContent: revealContent,
		hideContent: hideContent,
	};
})();

ARCADIA.POPUP_SERVICE = (function () {
	var renderPopup = function ($html) {
		$.colorbox({
			inline: true,
			href: $html,
			width: "500px",
			maxWidth: "95%",
			height: "auto",
		});
	};

	var attachHandlers = function () {
		$("body").on("click", ".js-close-popup", onClosePopupClick);
	};

	var onClosePopupClick = function (event) {
		event.preventDefault();
		closeAllPopups();
	};

	var closeAllPopups = function () {
		parent.$.colorbox.close();
	};

	var renderErrorMessage = function (message) {
		renderMessage(message, "error");
	};

	var renderSuccessMessage = function (message) {
		renderMessage(message, "success");
	};

	var renderMessage = function (message, cssClass) {
		var $div = $("<div></div>");
		var $message = $("<p>" + message + "</p>").addClass(cssClass);
		var $okButton = $('<button type="button">Ok</button>')
			.addClass("js-close-popup")
			.addClass("btn");

		$div.append($message).append($okButton);
		renderPopup($div);
	};

	return {
		renderPopup: renderPopup,
		closeAllPopups: closeAllPopups,
		attachHandlers: attachHandlers,
		renderErrorMessage: renderErrorMessage,
		renderSuccessMessage: renderSuccessMessage,
	};
})();

ARCADIA.TOOL_TIP_SERVICE = (function () {
	var initialise = function () {
		$(function () {
			$("a, img").tooltip();
		});

		$(".arc-carousel img").tooltip({
			disabled: true,
		});
	};

	var renderOnElement = function ($element, content) {
		$element
			.tooltip({
				items: "a",
				content: content,
			})
			.tooltip("open");
	};

	return {
		initialise: initialise,
		renderOnElement: renderOnElement,
	};
})();

ARCADIA.AJAX_SERVICE = (function (popupService) {
	var formPostContentType = "application/x-www-form-urlencoded";

	var getIdeasListJSON = function (done, fail) {
		if (fail === null) {
			fail = defaultFail;
		}

		$.getJSON("/AjaxGetIdeasLists.aspx").done(done).fail(fail);
	};

	var submitCreateNewIdeasListData = function (
		listName,
		productId,
		done,
		fail
	) {
		if (fail === null) {
			fail = defaultFail;
		}

		var formData = {
			listName: listName,
			productId: productId,
		};

		$.ajax({
			type: "POST",
			async: true,
			url: "/AjaxSubmitIdeasListNameWithProduct.aspx",
			contentType: formPostContentType,
			data: formData,
		})
			.done(done)
			.fail(fail);
	};

	var addProductToIdeasList = function (listId, productId, done, fail) {
		if (fail === null) {
			fail = defaultFail;
		}

		var formData = {
			listId: listId,
			productId: productId,
		};

		$.ajax({
			type: "POST",
			async: true,
			url: "/AjaxAddProductToIdeasList.aspx",
			contentType: formPostContentType,
			data: formData,
		})
			.done(done)
			.fail(fail);
	};

	var submitForm = function ($form, done, fail) {
		if (fail === null) {
			fail = defaultFail;
		}

		var options = {
			url: $form.attr("action"),
			type: $form.attr("method"),
			data: $form.serialize(),
			async: true,
		};

		$.ajax(options).done(done).fail(fail);
	};

	var defaultFail = function () {
		popupService.renderErrorMessage(
			"There was a problem with the server.  Please try refreshing the screen before trying again later."
		);
	};

	return {
		getIdeasListJSON: getIdeasListJSON,
		submitCreateNewIdeasListData: submitCreateNewIdeasListData,
		addProductToIdeasList: addProductToIdeasList,
		submitForm: submitForm,
	};
})(ARCADIA.POPUP_SERVICE);

ARCADIA.NAV_SPACING_SERVICE = (function (screenInfoService) {
	var evenlySpaceLisThatContainLinksAlongUl = function (
		$ul,
		lengthOfAnyIcons,
		mobileLiWidthCallback
	) {
		if (mobileLiWidthCallback === null) {
			mobileLiWidthCallback = defaultLiWidthCallback;
		}

		if (screenInfoService.isInsideMobileNavBreakPoint()) {
			setWidthOfNavElementsForMobile($ul, mobileLiWidthCallback);
		} else {
			evenlySpaceLis($ul, lengthOfAnyIcons);
		}
	};

	var defaultLiWidthCallback = function () {
		return "100%";
	};

	var setWidthOfNavElementsForMobile = function ($ul, mobileLiWidthCallback) {
		var width = mobileLiWidthCallback();
		var $topLevelLis = $ul.children();
		$($topLevelLis).css("width", width);
	};

	var evenlySpaceLis = function ($ul, lengthOfAnyIcons) {
		var $topLevelLis = $ul.children("li");
		var $topLevelLinks = $topLevelLis.children("a");
		var noOfLis = $topLevelLis.length;
		var gap = getGapBetweenMenuItems();
		var totalLength =
			$topLevelLinks.text().length + gap * noOfLis + lengthOfAnyIcons;
		var percentageSpaceLeft = 100;

		$topLevelLis.each(function (i) {
			var lastMenuItem = i === noOfLis - 1;
			var $parentLi = $(this);
			var $a = $parentLi.children("a");
			var length = $a.text().length + gap;
			if (!lastMenuItem) {
				var widthOfLi = (100 * length) / totalLength;
			} else {
				widthOfLi = percentageSpaceLeft;
			}
			$parentLi.css("width", widthOfLi + "%");
			percentageSpaceLeft -= widthOfLi;
		});
	};

	var getGapBetweenMenuItems = function () {
		if (screenInfoService.isSmallDesktop()) {
			return 2;
		}
		return 6;
	};

	return {
		evenlySpaceLisThatContainLinksAlongUl: evenlySpaceLisThatContainLinksAlongUl,
	};
})(ARCADIA.SCREEN_INFO_SERVICE);

ARCADIA.MAIN_NAV = (function (
	contentRevealer,
	screenInfoService,
	navSpacingService
) {
	var attachHandlers = function () {
		$("#jsMainNavBtn").click(onNavBtnClick);

		$("#mainNav, #mainProductNav").on(
			"click",
			".js-not-clicked-yet",
			onMenuFirstClick
		);
		$("#mainNav, #mainProductNav").on(
			"blur",
			".js-been-clicked",
			onMenuLinkLossOfFocus
		);

		$(window).resize(onScreenResize);
	};

	var onMenuFirstClick = function (event) {
		var $a = $(this);
		$a.off("click", onMenuFirstClick);

		$a.removeClass("js-not-clicked-yet");
		$a.addClass("js-been-clicked");

		if (screenInfoService.isUserTouching()) {
			event.preventDefault();
		}
	};

	var onNavBtnClick = function (event) {
		var $btn = $(this);
		var $navUl = $btn.next("ul");
		$btn.toggleClass("js-nav-active");

		if ($btn.hasClass("js-nav-active")) {
			contentRevealer.revealContent($navUl);
		} else {
			contentRevealer.hideContent($navUl);
		}
	};

	var onMenuLinkLossOfFocus = function () {
		var $a = $(this);
		$a.addClass("js-not-clicked-yet");
		$a.removeClass("js-been_clicked");
	};

	var onScreenResize = function () {
		var $navUl = $("#mainNav > ul");
		navSpacingService.evenlySpaceLisThatContainLinksAlongUl(
			$navUl,
			2,
			null
		);
	};

	return {
		attachHandlers: attachHandlers,
		onScreenResize: onScreenResize,
	};
})(
	ARCADIA.CONTENT_REVEALER,
	ARCADIA.SCREEN_INFO_SERVICE,
	ARCADIA.NAV_SPACING_SERVICE
);

ARCADIA.PRODUCT_NAV = (function (screenInfoService, navSpacingService) {
	var attachHandlers = function () {
		$(window).resize(onScreenResize);
		$("#mainProductNav > ul > li").mouseenter(onTopLevelLiHover);
		$("#productNavWrapper").mouseleave(onExitNav);
	};

	var onTopLevelLiHover = function (event) {
		if (screenInfoService.isInsideMobileNavBreakPoint()) {
			return;
		}

		addProductHoverClassToBody();
		var $li = $(this);
		var $ul = $li.closest("ul");
		var $childLis = $ul.children();
		$childLis.removeClass("js-li-hovered");
		$li.addClass("js-li-hovered");
	};

	var addProductHoverClassToBody = function () {
		$("body").addClass("js-product-nav-hover");
	};

	var removeProductHoverClassFromBody = function () {
		$("body").removeClass("js-product-nav-hover");
	};

	var onExitNav = function () {
		if (screenInfoService.isInsideMobileNavBreakPoint()) {
			return;
		}
		removeProductHoverClassFromBody();
		$("#mainProductNav > ul > li").removeClass("js-li-hovered");
	};

	var mobileLiWidthCallback = function () {
		if (screenInfoService.isSmallMobile()) {
			return "50%";
		}
		if (screenInfoService.isMobile()) {
			return "33.333333333%";
		}
		return "25%";
	};

	var onScreenResize = function () {
		var $navUl = $("#mainProductNav > ul");
		navSpacingService.evenlySpaceLisThatContainLinksAlongUl(
			$navUl,
			2,
			mobileLiWidthCallback
		);
	};

	return {
		attachHandlers: attachHandlers,
		onScreenResize: onScreenResize,
	};
})(ARCADIA.SCREEN_INFO_SERVICE, ARCADIA.NAV_SPACING_SERVICE);

ARCADIA.CAROUSEL = (function () {
	var attachHandlers = function () {
		$(".default-slider .owl-carousel").owlCarousel({
			items: 1,
			autoplay: true,
			loop: true,
			smartSpeed: 2500,
			autoplayTimeout: 10000,
		});

		$(".default-5-slider .owl-carousel").owlCarousel({
			autoplay: true,
			smartSpeed: 300,
			loop: true,
			items: 5,
			dots: false,
			responsive: {
				0: {
					items: 1,
				},
				500: {
					items: 2,
				},
				784: {
					items: 3,
				},
				980: {
					items: 4,
				},
				1200: {
					items: 5,
				},
			},
		});
	};

	return {
		attachHandlers: attachHandlers,
	};
})();

ARCADIA.TWITTER = (function () {
	var orange = "#f59a2a";

	var RenderTweets = function () {
		window.twttr = (function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0],
				t = window.twttr || {};
			if (d.getElementById(id)) return t;
			js = d.createElement(s);
			js.id = id;
			js.src = "https://platform.twitter.com/widgets.js";
			fjs.parentNode.insertBefore(js, fjs);

			t._e = [];
			t.ready = function (f) {
				t._e.push(f);
			};

			return t;
		})(document, "script", "twitter-wjs");
	};

	var attachHandlers = function () {
		$(".twitter-block").on(
			"DOMSubtreeModified propertychange",
			"#twitter-widget-1",
			onTweetsRendered
		);
	};

	var onTweetsRendered = function () {
		if (requiredElementsHaveBeenAdded()) {
			$(".twitter-block").off(
				"DOMSubtreeModified propertychange",
				"#twitter-widget-1"
			); //prevent us getting into an infinite loop
			$(".twitter-timeline")
				.contents()
				.find(" footer, .timeline-Header")
				.css("display", "none");
			//$(".twitter-timeline").contents().find(".TweetAuthor").css("display", "none");
			$(".twitter-timeline")
				.contents()
				.find("h1, p a, h1 a")
				.css("color", orange);
			$(".twitter-timeline")
				.contents()
				.find(".timeline-Header-byline")
				.css("color", "#000");
			$(".twitter-timeline")
				.contents()
				.find(".timeline-Tweet-media")
				.css("max-width", "50px");

			$(".twitter-block").css("height", "100%");
			addPictureLink();
		}
	};

	var requiredElementsHaveBeenAdded = function () {
		return $(".twitter-timeline").contents().find(".timeline-Header")
			.length;
	};

	var addPictureLink = function () {
		var $pictureLink = $(".twitter-timeline")
			.contents()
			.find(".timeline-Tweet-media a")
			.first();
		var pictureLinkHref = $pictureLink.attr("href");

		var $textLink = $("<a></a>")
			.attr("href", pictureLinkHref)
			.css("color", orange)
			.css("padding-left", "20px")
			.text("see more");

		var $tweetText = $(".twitter-timeline")
			.contents()
			.find("p.timeline-Tweet-text")
			.first();

		$tweetText.append($textLink);
	};

	return {
		attachHandlers: attachHandlers,
		RenderTweets: RenderTweets,
	};
})();

ARCADIA.ADD_TO_IDEASLIST = (function (ajaxService, popupService) {
	var attachHandlers = function () {
		$("a[data-ideas-product-id]").on("click", onAddToIdeasListClick);
		$("body").on(
			"click",
			"button[data-ideas-product-id]",
			onCreateNewIdeasList
		);
		$("body").on(
			"click",
			"input[type=checkbox][data-ideas-product-id]",
			onSelectIdeasListForProduct
		);
	};

	var currentProductId;

	var onAddToIdeasListClick = function (event) {
		event.preventDefault();
		var $a = $(this);
		currentProductId = $a.data("ideas-product-id");
		ajaxService.getIdeasListJSON(onIdeaListsReceived);
	};

	var onIdeaListsReceived = function (json) {
		var ideasListFormRenderer = _.template($("#ideaslist-template").html());
		var $ideasListFormHtml = $(
			ideasListFormRenderer({ ideas: json, productId: currentProductId })
		);
		popupService.renderPopup($ideasListFormHtml);
	};

	var onCreateNewIdeasList = function (event) {
		event.preventDefault();
		var $btn = $(this);
		var productId = $btn.data("ideas-product-id");
		var ideasListName = $("#ideasListName").val();
		ajaxService.submitCreateNewIdeasListData(
			ideasListName,
			productId,
			onIdeasListCreated
		);
	};

	var onIdeasListCreated = function (json) {
		var response = JSON.parse(json);

		if (!response.Success) {
			popupService.renderErrorMessage(response.errorMessage);
		} else {
			addConfirmationBasketLink(response.ListId);
		}
	};

	var onSelectIdeasListForProduct = function (event) {
		event.preventDefault();
		var $cbx = $(this);
		var productId = $cbx.data("ideas-product-id");
		var listId = $cbx.data("ideas-list-id");
		ajaxService.addProductToIdeasList(
			listId,
			productId,
			onProductAddedToList
		);
	};

	var onProductAddedToList = function (json) {
		var response = JSON.parse(json);

		if (!response.Success) {
			popupService.renderErrorMessage(response.errorMessage);
		} else {
			addConfirmationBasketLink(response.ListId);
		}
	};

	var renderJustLoggedInIdeasForm = function () {
		if ($("div[data-product-added-to-ideas-list]").length > 0) {
			var $div = $("div[data-product-added-to-ideas-list]").first();
			currentProductId = $div.data("product-added-to-ideas-list");
			$div.remove();
			ajaxService.getIdeasListJSON(onIdeaListsReceived);
		}
	};

	var addConfirmationBasketLink = function (listId) {
		$(".arc-idea-added").remove();
		var ideasAddedLinkRenderer = _.template(
			$("#addedToIdeasLink-template").html()
		);
		var $link = $(ideasAddedLinkRenderer({ listId: listId }));
		var $a = $("a[data-ideas-product-id=" + currentProductId + "]");
		$a.after($link);
	};

	return {
		attachHandlers: attachHandlers,
		renderJustLoggedInIdeasForm: renderJustLoggedInIdeasForm,
	};
})(ARCADIA.AJAX_SERVICE, ARCADIA.POPUP_SERVICE, ARCADIA.TOOL_TIP_SERVICE);

ARCADIA.IDEASLIST_ACTIONS = (function (ajaxService, popupService) {
	var attachHandlers = function () {
		$("a[data-email-ideas-id]").on("click", onRequestToEmailToFriend);
		$("a[data-rename-ideas-id]").on("click", onRenameIdeasListRequest);
		$("a[data-request-quote-ideas-id]").on(
			"click",
			onConvertIdeasToQuoteRequest
		);
		$("body").on("submit", "#submitIdeasListEmail", onSubmitEmailIdeasForm);
	};

	var onRequestToEmailToFriend = function (event) {
		event.preventDefault();
		var $a = $(this);
		var ideasListId = $a.data("email-ideas-id");
		renderForm("#ideaslistemailform-template", ideasListId);
	};

	var onSubmitEmailIdeasForm = function (event) {
		event.preventDefault();
		popupService.renderPopup("<p>Email being sent&hellip;</p>");
		var $form = $(this);
		ajaxService.submitForm($form, onIdeasListFormSubmit);
	};

	var onIdeasListFormSubmit = function (json) {
		var response = JSON.parse(json);
		if (!response.Success) {
			popupService.renderErrorMessage(response.ErrorMessage);
		} else {
			popupService.renderSuccessMessage("Your email has been sent!");
		}
	};

	var onRenameIdeasListRequest = function (event) {
		event.preventDefault();
		var $a = $(this);
		var ideasListId = $a.data("rename-ideas-id");
		renderForm("#renameideaslistform-template", ideasListId);
	};

	var onConvertIdeasToQuoteRequest = function (event) {
		event.preventDefault();
		var $a = $(this);
		var ideasListId = $a.data("request-quote-ideas-id");

		var $form = $(
			'<form action="copyIdeaToEnquiry.aspx" method="post"></form>'
		);
		var $hiddenField = $('<input type="hidden" />')
			.attr("name", "ideasListId")
			.attr("id", "ideasListId")
			.val(ideasListId);

		$form.append($hiddenField).appendTo("body").submit();
	};

	var renderForm = function (templateHtmlId, ideasListId) {
		var formRenderer = _.template($(templateHtmlId).html());
		var $formHtml = $(formRenderer({ ideasListId: ideasListId }));
		popupService.renderPopup($formHtml);
	};

	return {
		attachHandlers: attachHandlers,
	};
})(ARCADIA.AJAX_SERVICE, ARCADIA.POPUP_SERVICE);

ARCADIA.WISHLIST_ACTIONS = (function (ajaxService, popupService) {
	var attachHandlers = function () {
		$("a[data-delete-list-id]").on("click", onDeleteListRequest);
	};

	var onDeleteListRequest = function (event) {
		event.preventDefault();
		var $a = $(this);
		var listId = $a.data("delete-list-id");
		renderForm("#deleteListForm-template", listId);
	};

	var renderForm = function (templateHtmlId, listId) {
		var formRenderer = _.template($(templateHtmlId).html());
		var $formHtml = $(formRenderer({ listId: listId }));
		popupService.renderPopup($formHtml);
	};

	return {
		attachHandlers: attachHandlers,
	};
})(ARCADIA.AJAX_SERVICE, ARCADIA.POPUP_SERVICE);

ARCADIA.WISHLIST_TOOLTIP = (function (toolTipService) {
	var enquiryToolTipHtml;
	var ideasToolTipHtml;
	var singleQuoteToolTipHtml;

	var attachHandlers = function () {
		setToolTipHtml();
		$(".js-tooltip-ideas").on("mouseenter", onHoverOverAddToIdeas);
		$(".js-tooltip-enquiry").on("mouseenter", onHoverOverAddToEnquiry);
		$(".js-tooltip-singlequote").on("mouseenter", onHoverSingleQuote);
	};

	var setToolTipHtml = function () {
		enquiryToolTipHtml = $("#enquiryToolTip-template").html();
		ideasToolTipHtml = $("#ideasToolTip-template").html();
		singleQuoteToolTipHtml = $("#singleQuoteToolTip-template").html();
	};

	var onHoverOverAddToIdeas = function () {
		var $a = $(this);
		toolTipService.renderOnElement($a, ideasToolTipHtml);
	};

	var onHoverOverAddToEnquiry = function () {
		var $a = $(this);
		toolTipService.renderOnElement($a, enquiryToolTipHtml);
	};

	var onHoverSingleQuote = function () {
		var $a = $(this);
		toolTipService.renderOnElement($a, singleQuoteToolTipHtml);
	};

	return {
		attachHandlers: attachHandlers,
	};
})(ARCADIA.TOOL_TIP_SERVICE);

ARCADIA.VALIDATOR = (function () {
	var ValidatePasswordLength = function (sender, args) {
		var v = document.getElementById("txtNewPassword").value;
		if (v.length < 6) {
			args.IsValid = false;
		} else {
			args.IsValid = true;
		}
	};

	return {
		ValidatePasswordLength: ValidatePasswordLength,
	};
})();

ARCADIA.SEARCH = (function () {
	var attachHandlers = function () {
		$("body").on("click", ".js-search-link", onSearchClick);
		$("body").on("keyup", "#txtSearchTop", onSearchKeyPress);
		$(".main-form").on("submit", preventMainFormSubmitIfSearch);
	};

	var onSearchClick = function (e) {
		e.preventDefault();
		var $searchBar = $("#nt-search-bar");
		$searchBar.toggleClass("arc-active");
		if ($searchBar.hasClass("arc-active")) {
			$("#txtSearchTop").focus();
		}
	};

	var onSearchKeyPress = function (event) {
		if (event.keyCode === 13) {
			event.preventDefault();
			submitSearchGet();
		}
	};

	var submitSearchGet = function () {
		$("<form>")
			.attr("method", "get")
			.attr("action", "/PageView.aspx")
			.append($("#txtSearchTop").clone())
			.appendTo(document.body)
			.submit();
	};

	var preventMainFormSubmitIfSearch = function (e) {
		if ($("#txtSearchTop").is(":focus")) {
			e.preventDefault();
		}
	};

	return {
		attachHandlers: attachHandlers,
	};
})();

ARCADIA.SLIDER_RANGE = (function () {
	var init = function () {
		$(".js-slider-range").each(initiateSlider);
	};

	var initiateSlider = function () {
		var $sliderDiv = $(this);
		var minValue = $sliderDiv.data("slider-min-range");
		var maxValue = $sliderDiv.data("slider-max-range");
		var $sliderAmount = $($sliderDiv.data("slider-amount-element-id"));
		var $minHiddenField = $($sliderDiv.data("hidden-field-min-id"));
		var $maxHiddenField = $($sliderDiv.data("hidden-field-max-id"));

		$sliderDiv.slider({
			range: true,
			min: minValue,
			max: maxValue,
			values: [$minHiddenField.val(), $maxHiddenField.val()],
			slide: function (event, ui) {
				$sliderAmount.html("£" + ui.values[0] + " - £" + ui.values[1]);
				$minHiddenField.val(ui.values[0]);
				$maxHiddenField.val(ui.values[1]);
			},
		});
		$sliderAmount.html(
			"£" +
				$sliderDiv.slider("values", 0) +
				" &mdash; £" +
				$sliderDiv.slider("values", 1)
		);
	};

	return {
		init: init,
	};
})();

ARCADIA.SUB_NAVIGATION = (function () {
	var init = function () {
		renderNavigation();
		//setClickEvent();
		window.addEventListener("resize", renderNavigation);
	};

	// Gets called every time the window is resized
	function renderNavigation() {
		var subNavWrapper = document.getElementById("productNavWrapper");
		var mobileNavContainer = document.getElementById(
			"mobile_nav_container"
		);

		if (window.innerWidth >= 1024) {
			subNavWrapper.parentNode.style.display = "block";

			if (mobileNavContainer !== undefined && mobileNavContainer !== null)
				mobileNavContainer.parentNode.removeChild(mobileNavContainer);

			return;
		}

		if (mobileNavContainer !== undefined && mobileNavContainer !== null)
			return;

		var mainNavigation = document.getElementById(
			"mainNav_navMain_ulNavRoot"
		);
		var subNavigation = document.getElementById(
			"subMenu_navCategories_ulNavRoot"
		);
		var container = document.createElement("li");
		container.setAttribute("id", "mobile_nav_container");
		container.setAttribute("class", "productNavWrapperWrapper");
		subNavigation.style.height = "auto";
		mainNavigation.appendChild(container);
		container.innerHTML = subNavWrapper.parentNode.innerHTML;

		subNavWrapper.parentNode.style.display = "none";
	}

	return {
		render: init,
	};
})();

ARCADIA.VIDEO = (function (popupService) {
	var attachHandlers = function () {
		$("a[data-arc-video-link]").on("click", onRequestVideo);
	};

	var onRequestVideo = function () {
		var $a = $(this);
		var link = $a.data("arc-video-link");

		var videoIframeRenderer = _.template($("#videoIframe-template").html());
		var $videoIframeHtml = $(videoIframeRenderer({ link: link }));
		popupService.renderPopup($videoIframeHtml);
	};

	return {
		attachHandlers: attachHandlers,
	};
})(ARCADIA.POPUP_SERVICE);

ARCADIA.MESSAGETOOLS = (function () {
	var attachHandlers = function () {
		$("#divMessages a img.icon").click(function () {
			$("#divMessages").hide();
		});
	};

	return {
		attachHandlers: attachHandlers,
	};
})();

$(document).ready(function () {
	ARCADIA.SCREEN_INFO_SERVICE.attachHandlers();
	ARCADIA.MAIN_NAV.attachHandlers();
	ARCADIA.MAIN_NAV.onScreenResize();
	ARCADIA.CAROUSEL.attachHandlers();
	ARCADIA.TWITTER.attachHandlers();
	ARCADIA.TWITTER.RenderTweets();
	ARCADIA.ADD_TO_IDEASLIST.attachHandlers();
	ARCADIA.IDEASLIST_ACTIONS.attachHandlers();
	ARCADIA.POPUP_SERVICE.attachHandlers();
	ARCADIA.ADD_TO_IDEASLIST.renderJustLoggedInIdeasForm();
	ARCADIA.WISHLIST_ACTIONS.attachHandlers();
	ARCADIA.WISHLIST_TOOLTIP.attachHandlers();
	ARCADIA.TOOL_TIP_SERVICE.initialise();
	ARCADIA.SEARCH.attachHandlers();
	ARCADIA.PRODUCT_NAV.attachHandlers();
	ARCADIA.PRODUCT_NAV.onScreenResize();
	ARCADIA.SLIDER_RANGE.init();
	ARCADIA.MESSAGETOOLS.attachHandlers();
	ARCADIA.VIDEO.attachHandlers();
	ARCADIA.SUB_NAVIGATION.render();
	//ARCADIA.INSTAGRAM_FEED.init();
	$("a.lightbox").colorbox({ photo: true, rel: "gal" });

	InitialiseProductList();
});
