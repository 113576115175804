import React from "react";
import ReactDOM from "react-dom";
import List from "./list";

const Initialise = () => {
	const productsContainer = document.getElementById("productsContainer");
	if (!productsContainer) {
		return;
	}

	const productData = JSON.parse(
		productsContainer.getAttribute("data-products")
	);

	ReactDOM.render(<List products={productData} />, productsContainer);
};

export default Initialise();
