import React from "react";
import LazyLoad from "react-lazyload";
import classNames from "classnames";

const Product = (data, idx) => {
	return (
		<li
			key={idx}
			className={classNames({
				clear: idx % 3 === 0,
			})}
		>
			<a href={data.SeoUrl}>
				<div className="product-image">
					<LazyLoad offset={100} once>
						<img src={data.ImageFilepath} alt={data.NavTitle} />
						{data.BestSellerImageFilePath ? (
							<img
								className="product-overlay-image"
								src={data.BestSellerImageFilePath}
								alt="Best Seller"
							/>
						) : null}
					</LazyLoad>
				</div>
				<div className="product-info">
					<p>{data.NavTitle}</p>
					<span className="price">
						<span className="amount">{data.Amount}</span>
					</span>
				</div>
			</a>
			<a
				href={data.IdeasUrl}
				className="btn btn-small arc-ideas js-tooltip-ideas"
				{...(data.Authenticated
					? { "data-ideas-product-id": data.ProductId }
					: {})}
			>
				<i className="nt-icon-heart"></i> Add to Ideas
			</a>
		</li>
	);
};

export default Product;
